import React, { useState, useEffect } from "react";
import './App.css';
import qrCode from "./afdian-kandasorata.jpg";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import ReactQuill from 'react-quill';
import { exportCompletionToPDF } from "./util/completionExporter";
import 'react-quill/dist/quill.snow.css';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Popover } from 'antd';
const { TextArea } = Input;

async function getChatComplete(prompt, essay, setPromptStatus, setGeneration) {
  if (prompt === "") {
    setPromptStatus("error");
    return;
  }

  fetch("https://rxaznmruir2sfqqfyr7stacn6a0mqbel.lambda-url.us-east-1.on.aws", {
    method: 'POST',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      completionType: "COLLEGE_APPLICATION_ESSAY",
      userInput: [
        prompt,
        essay
      ]
    }),
  }).then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();

    // Recursive function to read the stream
    const readStream = (result = '') => {
      return reader.read().then(({done, value}) => {
        if (done) {
          return result;
        } else {
          const lines = new TextDecoder("utf-8")
                          .decode(value)
                          .split('\n')
                          .filter(line => line.trim() !== '');

          for (const line of lines) {
            const message = line.replace(/^data: /, '');
            if (message === '[DONE]') {
                return; // Stream finished
            }
            try {
                const parsed = JSON.parse(message);
                if (parsed.choices[0].finish_reason !== null) {
                  return;
                }
                result += parsed.choices[0].delta?.content;
                setGeneration(result); // update the state with the new chunk of data
            } catch(error) {
                console.error('Could not JSON parse stream message', message, error);
            }
          }
          return readStream(result);
        }
      });
    };

    return readStream();
  })
  .catch(error => console.error(error));
}

function App() {
  const [generation, setGeneration] = useState('## Hello World');
  const [promptEditorValue, setPromptEditorValue] = useState('');
  const [promptStatus, setPromptStatus] = useState("");
  const [essayEditorValue, setEssayEditorValue] = useState('');

  const promptPlaceHolder = "Paste the prompt of your college application here...";
  const essayPlaceHolder = "Paste or write your college application essay here...";
  
  const centeredButtonStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 24,
  }

  const functionalities = ["文书评测与生成", "敬请期待"];
  const otherLinks = ["施舍作者"];

  useEffect(() => {
    if (promptStatus !== "" && promptEditorValue !== "") {
      setPromptStatus("");
    }
  }, [promptStatus, promptEditorValue]);

  const qrCodeImage = (<img className="qr-code" src={qrCode} />);

  return (
    <div>
      <header className="header">
        <div className="header-left">
          {/* <img src={logo} alt="My Image" />; */}
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[functionalities[0]]}
            items={functionalities.map((functionality) => {
              return {
                key: functionality,
                label: functionality,
              };
            })}
          />
        </div>
        <div className="header-right">
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[otherLinks[0]]}
            items={otherLinks.map((otherLink) => {
              return {
                key: otherLink,
                label: (<Popover placement="bottom" content={qrCodeImage} arrow={true}>
                          <div className="pop-over">
                            {otherLink}
                          </div>
                        </Popover>
                ),
              };
            })}
          />

        </div>
      </header>
      <div className="main-container">
        <div className="component"> 
          <ReactMarkdown className="markdown-body markdown-container" children={"## 文书题目"} />
          <TextArea showCount status={promptStatus} maxLength={200} style={{ height: 50, resize: "none", marginBottom: 24 }} onChange={(e) => setPromptEditorValue(e.target.value)} placeholder={promptPlaceHolder} autoSize={{ minRows: 2 }}/>
          <ReactMarkdown className="markdown-body markdown-container" children={"## 文书内容"} />
          <ReactQuill className="text-editor" theme="snow" placeholder={essayPlaceHolder} value={essayEditorValue} onChange={setEssayEditorValue} />
          <Button type="primary" shape="round" icon={<UploadOutlined />} onClick={async () => await getChatComplete(promptEditorValue, essayEditorValue, setPromptStatus, setGeneration)} size="large" style={centeredButtonStyle}>生成</Button>
        </div>
        <div className="component">
          <div id="generated-markdown">
            <ReactMarkdown id="generated-markdown" className="markdown-body markdown-container" children={generation} remarkPlugins={[gfm]} />
          </div>
          <Button type="primary" shape="round" icon={<DownloadOutlined />} size="large" style={centeredButtonStyle} onClick={async () => await exportCompletionToPDF()}>导出</Button>
        </div>
      </div>
    </div>
    
  );
}

export default App;
