import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver'

export async function exportCompletionToPDF() {
    const node = document.getElementById('generated-markdown');

    return html2canvas(node).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        saveAs(imgData, 'export.png');
    });
}